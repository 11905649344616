import { useEffect, createRef, useRef, useState } from 'react'
import { sample } from 'lodash'
import './App.css';
import * as Matter from 'matter-js'
//x is the center point of the rectangle, not the top left corner.
class RodSegment {
  constructor(x, y, width, height, options) {
      this.static = !!options.fixed
      this.tip = !!options.tip

      const segmentOptions = {
        chamfer: 0,
        isStatic: this.static,
        render: {
          fillStyle: '#000'
        }
      }
    this.body = Matter.Bodies.rectangle(x, y, width, height, segmentOptions) 

  }

  getBody() {
    return this.body
  }
}
class Rod {
  constructor(beginX, beginY) {
    this.shortSectionHeight = 30
    this.shortSectionWidth= 10
    this.segments = []
    this.rod = null;
    this.fish = Matter.Bodies.circle(beginX + 300, beginY - 30, 10, { isStatic: true})
    const rows = 20


    const columns = 1

    const constraintOptions = {
      stiffness: 1,
      damping: 0.5,
      label: `foo`,
      render: {
        type: 'line',
        lineWidth: 10,
        strokeStyle: 'color: #ffffff; opacity: 1',
        anchors: false
      }
    }

    const rodSoftBody = Matter.Composites.stack(beginX, beginY - rows * this.shortSectionHeight, columns, rows, 0, 0, (x, y, _, i) => {
      const isRodBottom = i === rows - 1
      const isRodTip = i === 0;
      const segment = this.createSegment(x, y, isRodBottom, isRodTip)
      this.segments.push(segment)
      return segment.getBody() 
    })

    const fishConstraintOptions = {
      stiffness: 0.05,
      damping: 0.05,
      render: {
        type: 'line',
        lineWidth: 1
      }
    }


    console.log(this.getTip())
    this.fishConstraint = Matter.Constraint.create({
      bodyA: this.getTip().body,
      bodyB: this.fish
    })
    this.rod = Matter.Composites.chain(rodSoftBody, 0, 0.5, 0, -0.5, constraintOptions)
  }

  getRod() {
    return this.rod
  }

  getFish() {
    return this.fish
  }

  getFishConstraint() {
    return this.fishConstraint
  }

  getTip() {
    return this.segments[0]
  }

  getBase() {
    return this.segments[this.segments.length - 1]
  }

  createSegment(x, y, isRodBottom = false, isRodTip = false) {
    return new RodSegment(x, y, this.shortSectionWidth, this.shortSectionHeight, {fixed: isRodBottom, tip: isRodTip})
  }
}

function generateRodSet(rodAmount, canvasWidth, canvasHeight) {

  let rods = []
  const interval = canvasWidth / rodAmount

  for (let i = 1; i <= rodAmount; i++) {
    console.log(i)
    const rod = new Rod((canvasWidth - interval * i) + interval / 2, canvasHeight)

    rods.push(rod)
  }

  return rods
}

function App() {
  const simDiv = createRef();
  const gameRendered = useRef(false)
  let twitches = 0
  document.title = 'rod tips only dot com'

  useEffect(() => {
    //because apparently strict mode will render twice otherwise
    if (gameRendered.current === true) return
    console.log(window.innerHeight, window.innerWidth)
    const canvasHeight = window.innerHeight - 50
    const canvasWidth = window.innerWidth - 50
    const engine = Matter.Engine.create({ gravity: { y: -9 }})
    const runner = Matter.Runner.create()

    const rods = generateRodSet(5, canvasWidth, canvasHeight)

    Matter.Composite.add(engine.world, rods.map(rod => rod.getRod()))
    
    let render = Matter.Render.create({
      element: simDiv.current,
      engine,
      options: {
        width: canvasWidth,
        height: canvasHeight,
        wireframes: false,
        background: '#fff'
      },
    })

    Matter.Render.run(render)
    Matter.Runner.run(runner, engine)

    const debug = false;
    if (debug) {
      const mouse = Matter.Mouse.create(render.canvas)

      const mouseConstraint = Matter.MouseConstraint.create(engine, {
          mouse: mouse,
          constraint: {
              stiffness: 0.2,
              render: {
                  visible: false
              }
          }
      })

      Matter.Composite.add(engine.world, mouseConstraint)

      Matter.Events.on(mouseConstraint, 'mousedown', (e) => {
        console.log(e.mouse.absolute.x, e.mouse.absolute.y) 
      })

      render.mouse = mouse;

    }

    let lastTime = 0;
    let fishOn = false;

    Matter.Events.on(engine, 'beforeUpdate', (e) => {
      const timeScale = (e.delta || (1000 / 60)) / 1000;
      console.log(engine.timing.timestamp / 1000)

      const waveActionDeltaX = Math.sin(engine.timing.timestamp * 0.0005) * 0.005
      const waveActionDeltaY = Math.cos(engine.timing.timestamp * 0.0005) * 0.05

      rods.forEach((rod) => {
        const currentPosition = rod.getBase().getBody().position;
        Matter.Body.setPosition(rod.getBase().getBody(), {x: currentPosition.x + waveActionDeltaX, y: currentPosition.y + waveActionDeltaY  })

      })

      if (fishOn) {
        //start pulling on the tip, probably through a setPosition with a vector
      }

      if (Math.random() < .0007) {
        twitches++
        const rodTip = sample(rods).getTip()
        Matter.Body.applyForce(rodTip.getBody(), rodTip.body.position, {
          x: .01,
          y: .005
        })
      }


    })

    /*
    Matter.Events.on(engine, 'afterUpdate', (e) => {
      //This is going to be the main loop of the game.
      
      if (Math.random() < .0007) {
        console.log('twitch')
        twitches++
        const rodTip = sample(rods).getTip()
        Matter.Body.applyForce(rodTip.body, rodTip.body.position, {
          x: .005,
          y: .005
        })
      }
    })
    */

    return () => gameRendered.current = true
  }, [simDiv])

  return (
    <>
    <div ref={simDiv}>
    </div>
    </>
  );
}

export default App;
